import { AEngine } from "../../core/AEngine.js";
import { EVENTS } from "../../services/AEventService.js";
import { ATimeService } from "../../services/ATimeService.js";
import { toast } from "../../utils/toasts.js";
import { formatStringToPascal, AShowTable } from "../../utils/tools.js";
import { COLUMN_ACTION } from "../AGridTypes.js";
import { AWindowBase } from "../windows/AWindowBase.js";
export class ANodeSessionWindow extends AWindowBase {
    constructor(opt) {
        super();
        this.opt = opt;
        this.timeService = AEngine.get(ATimeService);
        this.prefetch = this.genColumns();
        this.prefetch.then((columns) => {
            this.columns = columns;
        });
    }
    async genColumns() {
        const columns = [
            { field: 'Device', text: '' },
            {
                field: 'Status',
                text: '',
                htmlEncode: false,
                renderer: (opt) => {
                    const style = opt.record.ValidLogin ? "--fa-animation-duration: 1s; --fa-beat-scale: 0.8;" : '';
                    return ( /*html*/`
            <div class="menu-label children-inline-block">
              <span class="statusbar ${opt.record.Status}">
                <i class="fa-solid fa-circle fa-beat" style="${style}"></i>
                <span>${formatStringToPascal(opt?.record?.StatusString ?? '')}</span>
              </span>
            </div>
          `);
                }
            },
            { field: 'User', text: '' },
            { field: 'LastUpdated', text: '' },
            { field: 'StatusString', text: '', hidden: true },
            {
                field: 'ComState',
                text: '',
                ...COLUMN_ACTION({ iconCls: 'fa-regular fa-trash text-red', btnCls: 'btn-white' }),
            },
        ];
        await Translate.get(columns.map(c => c.field)).then((t) => {
            columns.map(c => c.text = t[c.field]);
        });
        return columns;
    }
    async fetchData() {
        const raw = this.opt.fetchData();
        return Promise.all(Object.keys(raw).map(async (NodeName) => {
            const s = raw[NodeName];
            if (s.Gps == null) {
                debugger;
            }
            const [timeAgo, NodeNameTranslated] = await Promise.all([
                s.Gps ? this.timeService.ago(new Date(), new Date(s.Gps?.GpsTime ?? Date.now())) : '',
                Translate.get(NodeName)
            ]);
            return {
                'Device': NodeNameTranslated,
                'User': s.UserDisplayName || s.User,
                'StatusString': s.StatusString,
                'Status': s.Status,
                'ComState': s.ComState,
                'LastUpdated': timeAgo,
            };
        }));
    }
    async show() {
        await this.prefetch;
        let id = idAllocatorService.getNextId({ prefix: 'win-table-' });
        await super.generate({
            content: ( /*html*/`<div id="${id}" class="fw fh" style="min-width: 900px"></div>`),
            alignTo: 'center',
            height: '500px',
            lifeCycle: 'CONTINUOUS'
        });
        this.grid = AShowTable({
            appendTo: id,
            aci: {
                resizeToFit: true,
                resizeToFitReverse: true
            },
            columns: this.columns,
            data: await Loading.waitForPromises(this.fetchData())
        });
        // Update targets
        this.eventId = Events.hardwire(EVENTS.SESSION_UPDATE_DATA, async () => {
            this.grid.data = await Loading.waitForPromises(this.fetchData());
        });
        return window;
    }
    close() {
        toast({ msg: 'Not Implemented Yet!' });
        this.$internal.remove();
        this.destruct();
    }
    destruct() {
        Events.off(EVENTS.SESSION_UPDATE_DATA, this.eventId);
    }
}
