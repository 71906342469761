import { _getEle$ } from "../../utils/maps.js";
import { initTableAccordions } from "../../utils/table_accordions.js";
import { AInfoWindowHelper } from "../AInfoWindowHelper.js";
export class AWindowBase {
    async generate(opt) {
        // this.id = idAllocatorService.getNextId({prefix: 'win-'})
        const { alignTo, content, height } = opt;
        const parent = opt.lifeCycle === 'CONTINUOUS' ? 'body' : '#AjaxContent';
        let $win = $(`
      <div class="awin custom-scroll">
        <div class="awin-header">
          <i class="exit fa-light fa-xmark fa-fw"></i>
        </div>
        <div class="awin-content">
          ${content}
        </div>
      </div>
    `);
        $win.css(height ? { height } : {});
        initTableAccordions($win);
        const fheight = $win.find('.awin-content tr').length * 21;
        $win.attr('summary-height', fheight);
        $win.attr('full-height', fheight * 2);
        AInfoWindowHelper.initDraggableWindow({ $window: $win });
        $win.find('.exit').on('click', () => this.close());
        $win.appendTo(parent ?? '#AjaxContent');
        const offset = alignTo === 'center' ? this.getCenterOffset($win) : _getEle$(alignTo ?? parent ?? '#AjaxContent').offset();
        $win.css({ ...offset });
        this.$internal = $win;
    }
    getCenterOffset($win) {
        let { innerWidth: w, innerHeight: h } = window;
        return {
            left: (w - $win.width()) / 2,
            top: (h - $win.height()) / 2,
        };
    }
    show() {
        throw new Error("Method not implemented.");
    }
    close() {
        throw new Error("Method not implemented.");
    }
    destruct() {
        throw new Error("Method not implemented.");
    }
}
