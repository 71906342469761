import { AError } from "../../classes/AError.js";
import { AEngine } from "../../core/AEngine.js";
import { ATemplates } from "../../core/ATemplateService.js";
import { createMap } from "../../utils/maps.js";
import { ALERTS, ALERT_BUTTONS, ALERT_STATUS } from "../../services/AAlertService.js";
import { ADragService } from "../../services/ADragService.js";
import { AInputDate, AInputTime } from "../../utils/tools.js";
import { ARouteMapHelperService } from "../../services/ARouteMapHelperService.js";
import { AColorHSV } from "../../classes/AColorHSV.js";
export class APage {
    constructor() {
        this.waySegmentColors = {
            green: new AColorHSV(120, 100, 100),
            blue: new AColorHSV(192, 44, 87),
            red: new AColorHSV(0, 99, 99),
            lime: new AColorHSV(63, 100, 100),
            orange: new AColorHSV(32, 94, 100)
        };
        this.map = createMap('map', {
            zoom: 24
        });
        // Move legend to map
        this.map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push($("#map_legend").get(0));
        this.Devices = [];
        this.routeMapHelperService = AEngine.get(ARouteMapHelperService);
        this.routeMapHelperService.Clear();
    }
    async init() {
        this.map.fit();
        this.translations = await Loading.waitForPromises(Translate.get([
            'Delete route',
            'From',
            'Route will also be removed from planning'
        ]));
        AEngine.get(ADragService).createDraggableGeneric();
        this.showInfoScreen("disabled");
        if (await Loading.waitForPromises(routePlannerService.pollRouteService())) {
            this.geoMap = await Loading.waitForPromises(routePlannerService.fetchGeoMap());
            this.refresh();
        }
        //this.geoMap = await Loading.waitForPromises(routePlannerService.fetchGeoMap())
        //this.refresh()
    }
    async refresh() {
        this.showInfoScreen("splash");
        await Loading.waitForPromises(this.getCars()).then(response => {
            this.createCarList();
        }).catch(AError.handle);
    }
    showInfoScreen(screen) {
        $('#info_screen_disabled').hide();
        $('#info_screen_splash').hide();
        $('#info_screen_route').hide();
        if (screen === "disabled") {
            $('#info_screen_disabled').show();
        }
        else if (screen === "splash") {
            $('#info_screen_splash').show();
        }
        else if (screen === "route") {
            $('#info_screen_route').show();
        }
    }
    createCarList() {
        this.showInfoScreen("splash");
        $('#car_list').children().remove();
        $('#route_list').children().remove();
        this.map.fit();
        this.routeMapHelperService.removeWaySegments(this.map);
        var th = this;
        for (const device of Object.keys(th.DeviceCarRoutes)) {
            var $row = $(/*html*/ `
        <tr id="${device}" class='tableRow'>
        <td>
            <div class='columns' style="margin: 2px;">
            <h7 class='col-12'>${device}</h7>
          </div>
        </td>  
        </tr>
        `.replace(/\s\s+/g, ' '));
            $('#car_list').append($row);
            if (th.SelectedCar == device) {
                $row.trigger('click');
            }
        }
        $('#car_list').off();
        $('#car_list').on('click', 'tr', async function () {
            $("table > tbody > tr").each(function () {
                $(this).removeClass('selected');
            });
            $(this).addClass('selected');
            th.SelectedCar = this.id;
            th.onCarRowClicked(th.DeviceCarRoutes[this.id]);
        });
    }
    async onCarRowClicked(deviceCarRoute) {
        debugger;
        this.showInfoScreen("splash");
        $('#route_list').children().remove();
        await Loading.waitForPromises(this.getRoutes({ ForDevice: deviceCarRoute.DeviceName }));
        this.routeMapHelperService.removeWaySegments(this.map);
        this.map.fit();
        if (!deviceCarRoute.Routes || !deviceCarRoute.Routes.length)
            return;
        for (const index in deviceCarRoute.Routes) {
            const CarRoute = deviceCarRoute.Routes[index];
            let $name = CarRoute.RouteName;
            if (CarRoute.RouteFromTime && CarRoute.RouteToTime) {
                $name += "  " + (AInputDate(new Date(CarRoute.RouteFromTime)) + " " + AInputTime(new Date(CarRoute.RouteFromTime)) + " - " + AInputTime(CarRoute.RouteToTime));
            }
            let $spinner = ``;
            let $color = "black";
            if (!CarRoute.SendToCar) {
                $spinner = `<i class="col-1 fa-regular fa-spinner"></i>`;
                $color = "color: #A9A9A9;";
            }
            var $row = $(/*html*/ `
        <tr id="${index}" class='tableRow'>
        <td>
            <div class='columns' style="margin: 2px; ${$color}">
            <h7 class='col-11'>${$name}</h7>
            ${$spinner}
          </div>
        </td>  
        </tr>
        `.replace(/\s\s+/g, ' '));
            $('#route_list').append($row);
        }
        var th = this;
        $('#route_list').off();
        $('#route_list').on('click', 'tr', async function () {
            $("#route_list > tr").each(function () {
                $(this).removeClass('selected');
            });
            $(this).addClass('selected');
            await Loading.waitForPromises(th.onRouteRowClicked(deviceCarRoute.DeviceName, deviceCarRoute.Routes[this.id]));
        });
    }
    async onRouteRowClicked(deviceName, carRoute) {
        this.showInfoScreen("route");
        this.routeMapHelperService.removeWaySegments(this.map);
        var routeDBarr = await routePlannerService.fetchRoute(carRoute.RouteId, carRoute.CarNumber);
        let routeDB;
        if (routeDBarr.length >= 1) {
            routeDB = routeDBarr[0];
        }
        else {
            return;
        }
        let $btn_info = $('#info_route_info');
        let $btn_delete = $('#info_route_delete');
        $btn_info.off();
        $btn_delete.off();
        $btn_delete.on("click", () => this.alert_deleteRoute(deviceName, carRoute));
        if (carRoute.SendToCar)
            $("#info_route_warning").hide();
        else
            $("#info_route_warning").show();
        $('#info_route_name').text(carRoute.RouteName);
        $('#info_route_added').text(AInputDate(new Date(carRoute.DateAdded)));
        if (this.geoMap != undefined && this.geoMap.WaySegments != undefined && routeDB.RouteSegments != undefined && routeDB.RouteSegments.length) {
            let WaysegmentsParking = [];
            let WaysegmentsOther = [];
            for (const i in routeDB.RouteSegments) {
                const $s = routeDB.RouteSegments[i];
                let WaySegmentId = Math.abs($s.WaySegmentId);
                if (!this.geoMap.WaySegments[WaySegmentId])
                    continue;
                if ($s.ScanSide === "ScanLeft" || $s.ScanSide === "ScanRight" || $s.ScanSide === "ScanLeftAndRight" || $s.ScanSide === "ScanAny") {
                    if (WaysegmentsParking.indexOf(WaySegmentId) === -1) {
                        WaysegmentsParking.push(WaySegmentId);
                        this.routeMapHelperService.drawWaySegment(this.map, this.geoMap.WaySegments[WaySegmentId], this.waySegmentColors.red);
                    }
                }
                else {
                    if (WaysegmentsOther.indexOf(WaySegmentId) === -1) {
                        WaysegmentsOther.push(WaySegmentId);
                        this.routeMapHelperService.drawWaySegment(this.map, this.geoMap.WaySegments[WaySegmentId], this.waySegmentColors.orange);
                    }
                }
            }
            this.routeMapHelperService.fitBoundsWaySegments(this.map);
        }
    }
    async alert_deleteRoute($deviceName, $route) {
        let html_sch = ``;
        if ($route.RouteFromTime && $route.RouteToTime) {
            html_sch = /*html*/ `
      <label class="form-label">${this.translations['Route will also be removed from planning']}</label>     
    `;
        }
        const html = /*html*/ `
      <div class="form-group">
        <label class="form-label">${this.translations['Delete route']} ${this.translations['From']} ${$deviceName}: '${$route.RouteName}'?</label>
      </div>
      ${html_sch}`;
        const events = Alerts.show({
            translatedTitle: this.translations['Delete route'],
            content: html,
            type: ALERTS.Form,
            buttons: ALERT_BUTTONS.deleteCancel
        });
        var $page = this;
        events.on(ALERT_STATUS.ON_ACTION_PROCEED, async () => {
            var data = {
                "ShareId": $route.ShareId
            };
            CCCClient.SendMessage("PlanRoute_DeleteRouteFromCarByShareId_Request", 0, data, 0, {
                Type: "ControlCenter",
                IndexNumber: 1,
                CustomerNumber: CCCClient.NodeCustomerNumber,
                ProjectNumber: CCCClient.NodeProjectNumber
            });
            Events.once(`PlanRoute_DeleteRouteFromCarByShareId_Response`, response => {
                $page.refresh();
            });
        });
    }
    async getCars() {
        try {
            this.DeviceCarRoutes = [];
            let query = `SELECT 
        ForDevice
      FROM planroute_carroutes
      ORDER BY ForDevice`;
            let response = await requestService.query({
                Query: query
            });
            if (response.Rows.length === 0)
                return;
            for (let row of response.Rows) {
                //RouteId: string, RouteName: string, ForDevice: string, DateAdded: Date
                var DeviceName = row[0];
                this.DeviceCarRoutes[DeviceName] = {
                    DeviceName: DeviceName,
                    Routes: {}
                };
            }
        }
        catch (err) {
            AError.handle(err);
        }
        return Promise.resolve();
    }
    async getRoutes(options) {
        try {
            let query = `SELECT 
        c.ShareId,
        c.RouteId,
        c.CarNumber,
        c.RouteName, 
        c.SendToCar,
        c.DateAdded,
        p.RouteFromTime, 
        p.RouteToTime
      FROM planroute_carroutes c
      LEFT JOIN planroute_planning p 
      USING (ShareId)
      WHERE c.ForDevice = :ForDevice
      ORDER BY c.RouteName`;
            let response = await requestService.query({
                Params: options,
                Query: query
            });
            if (this.DeviceCarRoutes[options.ForDevice]) {
                this.DeviceCarRoutes[options.ForDevice].Routes = [];
            }
            else {
                let device = {
                    DeviceName: options.ForDevice,
                    Routes: []
                };
                this.DeviceCarRoutes[options.ForDevice] = device;
            }
            for (let row of response.Rows) {
                //RouteId: string, RouteName: string, ForDevice: string, DateAdded: Date
                var Route = {
                    ShareId: row[0],
                    RouteId: row[1],
                    CarNumber: row[2],
                    RouteName: row[3],
                    SendToCar: row[4],
                    DateAdded: row[5],
                    RouteFromTime: row[6] ? new Date(row[6]) : undefined,
                    RouteToTime: row[7] ? new Date(row[7]) : undefined
                };
                this.DeviceCarRoutes[options.ForDevice].Routes.push(Route);
            }
        }
        catch (err) {
            AError.handle(err);
        }
        return Promise.resolve();
    }
}
export function css() {
    return ( /*html*/`
    <style>   

      .fixTableHead {
        overflow-y: auto;
        height: 100%;
      }
      .fixTableHead thead th {
        position: sticky;
        background: #F9F9F9;
        top: 0;
      }
      .tableRow {
        background: #ffffff;
      }
      .tableRow:hover {
        background: #F9F9F9;
      }
      .tableRow.selected {
        background: #ECECEC;
      }

      .tableRowInActive {
        background: #F8F8F8;
        color: #BBBBBB;
      }
      .tableRowInActive.selected {
        background: #EBEBEB;
        color: #BBBBBB;
      }
      .tableRowInActive:hover {
        background: #F1F1F1;
      }
      
      .legend-label.legend-label-sub {
        font-size: 11px;
        line-height: 1.5em;
      }

      .accordion-wrapper > a{
        padding: 15px 8px
      }      

      .accordion-wrapper .tableWrapper {
        max-height: 400px;
        overflow-y: auto;
      }

      #info_overview .display-once {
        background: transparent;
      }

      #info_overview #info_overview_splash {
        display: flex;
        text-align: center;
        flex-wrap: nowrap;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
      
      #info_overview {
        height: 250px;
      }

      .info-block {
        display: flex;
        text-align: center;
        height: calc(100% / 3.0);
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
      }
      
      .info-block h6,
      .info-block span {
        margin-bottom: 0;
      }

      .mapWrapper {
        position: relative;
        height: calc(100% - 250px);
        width: 100%;
      }

      .aci-map + .legend {
        display: none;
      }
    </style>  
  `);
}
export function render() {
    return ( /*html*/`
    <div class="flex-child fh">
    <div class="flex-content">
  
      <div class="splitter two-split fh" style="overflow-y: auto; overflow-x: hidden;">
  
        <div class="drag-section part-one" style="width: 50%; min-width: 280px;">
  
          <div class="columns" style="overflow-y: auto; height: 100%;">
            <div class="column" style="overflow-y: auto; height: 100%; border-right: 1px lightgrey solid;">
              <div class="fixTableHead fh">
                <table style="table-layout: fixed; margin-left: 5px; width: 98%" class="table">
                  <thead>
                    <tr>
                      <th><b>Devices</b></th>
                    </tr>
                  </thead>
                  <tbody id="car_list">
                  </tbody>
                </table>
              </div>
            </div>
  
            <div class="column" style="overflow-y: auto; height: 100%;">
              <div class="fixTableHead fh">
                <table style="table-layout: fixed; width: 98%" class="table">
                  <thead>
                    <tr>
                      <th><b>Routes</b></th>
                    </tr>
                  </thead>
                  <tbody id="route_list">
                  </tbody>
                </table>
              </div>
            </div>
  
  
          </div>
        </div>
  
  
  
  
        <div id="separator1" class="drag-seperator separator"></div>
  
        <div class="drag-section part-two" style="width: 70%; min-width: 180px;">
          <div class="fh">
  
            <div class="mapWrapper" style="height: calc(100% - 200px)">
              <div id="map" class="aci-map"></div>
              <div class="legend legend-opaque" id="map_legend">
                <div class="legend-label label-height-lg hidden">Legend</div>
                <div class="legend-item">
                  <div class="route-preview" style="background-color: rgba(255, 0, 0, 0.7); border-color: #a30000"></div>
                  <span>With Parking</span>
                </div>
                <div class="legend-item">
                  <div class="route-preview" style="background-color: rgba(255, 143, 15, 0.7); border-color: #e08722">
                  </div>
                  <span>Without Parking</span>
                </div>
              </div>
            </div>
  
            <div id="info_screen" class="columns" style="height: 200px">
              <div id="info_screen_splash" class="columns col-12">
                <div style="margin: auto; text-align: center;">
                  <div template="${ATemplates.RouteInformation}"></div>
                </div>
              </div>

              <div id="info_screen_disabled" class="columns col-12">
                <div style="margin: auto; text-align: center;">
                  <h5 class='col-12' style="text-align: center; color: lightgray;">Service Not Running</h5>
                </div>
              </div>
  
              <div id="info_screen_route" class="columns col-12" style="display: none;">
  
                <div class="col-3" style="margin: auto; text-align: center;">
                  <h6>Route Name</h6>
                  <span id="info_route_name">ROUTENAME</span>
                </div>
  
                <div class="col-3" style="margin: auto; text-align: center;">
                  <h6>Added</h6>
                  <span id="info_route_added">DATE ADDED</span>
                </div>
  
                <div id="info_route_warning" class="col-3" style="margin: auto; text-align: center;">
                  <h6>NOT YET ON CAR</h6>
                  <i class="fa fa-warning" style="color: orange ;" aria-hidden="true"></i>
                </div>
  
                <div class="col-3" style="margin: auto; text-align: center;">
                  <h6 for="info_route_delete">Delete</h6>
                  <button style="width: 36px; height: 36px;  margin-top: 5px" id="info_route_delete"
                    class="btn btn-error">
                    <i class="fa fa-trash" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  `);
}
